import React, {createContext, useState, useEffect} from 'react';
import { Network } from '@capacitor/network';
import localforage from 'localforage';
import {saveUsersWorkoutProgress} from "./services/user.service";

export const QueueContext = createContext({});

const QueueProvider = ({ children }) => {
    const [isProcessing, setIsProcessing] = useState(false);

    // Add an item to the queue
    const addToQueue = async (item) => {
        const currentQueue = (await localforage.getItem('syncQueue')) || [];
        currentQueue.push({...item, timestamp: Date.now()});
        await localforage.setItem('syncQueue', currentQueue);
        await processQueue(); // Attempt to process queue after adding an item
    };


    // Process the queue, similar to the processQueue function you'll define
    // based on the earlier example
    const processQueue = async () => {
        if (isProcessing || !(await Network.getStatus()).connected) {
            return; // Exit if already processing or if offline
        }

        setIsProcessing(true); // Lock processing
        let queue = (await localforage.getItem('syncQueue')) || [];

        if (queue.length > 0 && (await Network.getStatus()).connected) {

            try {
                const response = await saveUsersWorkoutProgress({ workouts: queue });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                // If the request was successful, clear the queue
                queue = [];
                await localforage.setItem('syncQueue', queue); // Update the queue in storage
            } catch (error) {
                console.error('Failed to sync queue: ', error);
                // Consider notifying the user about synchronization errors and retrying
            }
        }

        setIsProcessing(false); // Unlock processing
    };

    useEffect(() => {
        // Periodically attempt to process the queue
        const intervalId = setInterval(async () => {
            await processQueue();
        }, 10000); // Adjust the interval as needed

        return () => clearInterval(intervalId); // Cleanup on unmount
    });

    return (
        <QueueContext.Provider value={{ processQueue, addToQueue}}>
            {children}
        </QueueContext.Provider>
    );
};

export default QueueProvider;