import React, {useState, useEffect, useContext} from 'react';
import {Context} from "../context";

const styles = {
    countdownContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        margin: "auto",
        right: 0,

    },
    svg: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        transform: "rotateY(-180deg) rotateZ(-90deg)",
        overflow: "visible",
    },
    button: {
        fontSize: 16,
        padding: "15px 40px",
        margin: "10px auto 30px",
        display: "block",
        backgroundColor: "#4d4d4d",
        color: "lightgray",
        border: "none",
        cursor: "pointer",
        outline: 0,
    },
};


const TimerCircle = ({ size, strokeColor, strokeWidth, strokeBgColor }) => {
    const radius = size / 2;
    const circumference = size * Math.PI;
    const {workoutTimer} = useContext(Context);
    const timeLeft = workoutTimer.currentPhase === 'Get Ready' ? workoutTimer.countDown : workoutTimer.timeLeftInSet;
    const isPlaying = workoutTimer.started && !workoutTimer.paused;

    const [totalTimeExpr, setTotalTimeExpr] = useState(timeLeft);

    useEffect(() => {
        setTotalTimeExpr(timeLeft);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workoutTimer.currentPhase]);

    const countdownSizeStyles = {
        height: size,
        width: size,
    };

    const textStyles = {
        color: 'black',
        fontSize: size * 0.36,
        fontWeight: 600,
    };

    const strokeDashoffset = () =>
        circumference - (timeLeft / totalTimeExpr) * circumference;

    return (
        <div
            style={{...styles.countdownContainer, ...countdownSizeStyles}}
        >
            <p style={textStyles}>{timeLeft}</p>
            <svg style={styles.svg}>
                <circle
                    cx={radius}
                    cy={radius}
                    r={radius}
                    fill="none"
                    stroke={strokeBgColor}
                    strokeWidth={strokeWidth}
                ></circle>
            </svg>
            <svg style={styles.svg}>
                <circle
                    style={{ transition: 'stroke-dashoffset 1s linear' }}
                    strokeDasharray={circumference}
                    strokeDashoffset={isPlaying ? strokeDashoffset() : 0}
                    r={radius}
                    cx={radius}
                    cy={radius}
                    fill="none"
                    strokeLinecap="round"
                    stroke={strokeColor}
                    strokeWidth={strokeWidth}
                ></circle>
            </svg>
        </div>
    );
};

export default TimerCircle;