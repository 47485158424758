import React, {useContext}  from 'react';
import './App.css';
import Header from './components/Header';
import WorkoutDetail from './components/WorkoutDetail';
import Dashboard from "./components/Dashboard";
import Mike5 from "./components/Mike5";
import {BrowserRouter, Routes, Route, Navigate} from 'react-router-dom';
import logo from "./assets/images/eisenhorn-logo.svg";
import {Context} from './context'
import WithAuth from './WithAuth';
import Login from "./components/Login";
import PopupModal from "./components/PopupModal";
import SliderModal from "./components/SliderModal";

function App() {
    const {loading, isPopupOpen, popupData, closePopup} = useContext(Context);

    if (loading) {
        return <div className="loading-screen">
            <img src={logo} loading="lazy" width="295" height="45" alt="EISENHORN Logo"/>
        </div>;
    }

  return (
    <div className="App">
        <BrowserRouter basename={process.env.PUBLIC_URL}>
            <Header />
            <Routes>
                <Route path="login" element={<Login />} />
                <Route path="/" element={<WithAuth Component={Dashboard} />} />
                <Route path="/mike5" element={<WithAuth Component={Mike5} />} />
                <Route path="/workout/:workoutSlug/:mike5Workout?" element={<WithAuth Component={WorkoutDetail} />} />
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
            {isPopupOpen && popupData.type !== 'slider' && <PopupModal {...popupData} closePopup={closePopup} />}
            {isPopupOpen && popupData.type === 'slider' && <SliderModal {...popupData} closePopup={closePopup} />}
        </BrowserRouter>
    </div>
  );
}

export default App;
