import { useState, useEffect, useRef } from 'react';
import {requestWakeLock, releaseWakeLock, dateAsWorkoutFormat, dateToISOStringKeepTimezone} from '../helpers';
import {useTranslation} from "react-i18next";
import useIsMobileSafari from "./useIsMobileSafari"

const useWorkoutTimer = (activeWorkout, activeMike5Workout, openPopup, selectedWorkoutType, addToQueue, setUserData, sfx, currentDate) => {
    const { t, i18n } = useTranslation();
    const isMobileSafari = useIsMobileSafari();

    const [workoutTimer, setWorkoutTimer] = useState({
        started: false,
        paused: false,
        currentPhase: 'Get Ready',
        workTime: 0,
        restTime: 0,
        sets: 0,
        setsCount: 0,
        currentSet: 1,
        timeLeftInSet: 0,
        timeSpent: 0,
        countDown: 10,
        totalTime: 0,
        intervalLink: '',
        currentExercise: 0,
        exercisesCount: 1,
        hasContinuosTimer: false,
    });

    const timerInterval = useRef();

    const playSoundAtCertainTimes = (timeLeft) => {
        if ([11, 4, 3, 2].includes(timeLeft)) {
            sfx.playBeep();
        }
    };

    const getNextTimerState = (prevState) => {
        const isGetReadyPhase = prevState.currentPhase === 'Get Ready';
        const isWorkPhase = prevState.currentPhase === 'Work';
        const isRestPhase = prevState.currentPhase === 'Rest';
        const { workTime } = activeWorkout.interval;

        playSoundAtCertainTimes(prevState.countDown > 0 ? prevState.countDown : prevState.timeLeftInSet)

        if (prevState.hasContinuosTimer) {
            // In the continuous timer mode
            if (isGetReadyPhase) {
                if (prevState.countDown > 1) {
                    return {...prevState, countDown: prevState.countDown - 1};
                } else {
                    // Transition to 'Work' phase, and reset
                    return {
                        ...prevState,
                        currentPhase: 'Work',
                        timeLeftInSet: 0,
                        timeSpent: prevState.timeSpent + 1
                    };
                }
            } else if (isWorkPhase) {
                return { ...prevState, timeLeftInSet: prevState.timeLeftInSet + 1, timeSpent: prevState.timeSpent + 1 }; // increment time
            }
        } else {
            if (isGetReadyPhase) {
                if (prevState.countDown > 1) {
                    return {...prevState, countDown: prevState.countDown - 1};
                } else {
                    sfx.playWork();
                    return {
                        ...prevState,
                        currentPhase: 'Work',
                        timeLeftInSet: prevState.workTime,
                        countDown: prevState.countDown - 1
                    };
                }
            } else if (isWorkPhase) {
                if (prevState.timeLeftInSet > 1) {
                    return {
                        ...prevState,
                        timeSpent: prevState.timeSpent + 1,
                        timeLeftInSet: prevState.timeLeftInSet - 1
                    };
                } else {
                    if (prevState.currentSet < prevState.setsCount) {
                        sfx.playRest();
                        return {
                            ...prevState,
                            currentPhase: 'Rest',
                            timeLeftInSet: prevState.restTime,
                            timeSpent: prevState.timeSpent + 1
                        };
                    } else {
                        return switchToNextExerciseOrEnd(prevState, workTime);
                    }
                }
            } else if (isRestPhase) {
                if (prevState.timeLeftInSet > 1) {
                    return {
                        ...prevState,
                        timeSpent: prevState.timeSpent + 1,
                        timeLeftInSet: prevState.timeLeftInSet - 1
                    };
                } else {
                    if (prevState.currentSet < prevState.setsCount) {
                        if(i18n.language === 'de') {
                            sfx.playWorkContinue();
                        } else {
                            sfx.playWork();
                        }
                        return {
                            ...prevState,
                            currentPhase: 'Work',
                            currentSet: prevState.currentSet + 1,
                            timeLeftInSet: prevState.workTime,
                            timeSpent: prevState.timeSpent + 1
                        };
                    } else {
                        if (prevState.currentExercise < prevState.exercisesCount - 1) {
                            return switchToNextExerciseOrEnd(prevState, workTime);
                        } else {
                            return {...prevState, started: false};
                        }
                    }
                }
            }
        }
    };

    const switchToNextExerciseOrEnd = (prevState, workTime) => {
        if (prevState.currentExercise < prevState.exercisesCount - 1) {
            sfx.playNextExercise();

            // Move to the next exercise and reset timer
            return {
                ...prevState,
                currentPhase: 'Get Ready',
                currentExercise: prevState.currentExercise + 1,
                currentSet: 1,
                timeLeftInSet: workTime,
                timeSpent: prevState.timeSpent + 1,
                countDown: 30
            };
        } else {
            addToQueue({
                action: 'completeWorkout',
                data: {
                    workoutId: activeWorkout.id,
                    mike5Component: activeWorkout.mike5Component ?? '',
                    completedDateTime:  dateToISOStringKeepTimezone(),
                    intendedCompletionDate: dateAsWorkoutFormat(currentDate)
                }
            });

            setUserData( (prevState) => {
                return {
                    ...prevState,
                    workout_progress: [
                        ...prevState.workout_progress,
                        {
                            workout_id: activeWorkout.id,
                            mike5Component: activeWorkout.mike5Component ?? '',
                            completed_datetime:  dateAsWorkoutFormat(),
                            intended_completion_date: dateAsWorkoutFormat(currentDate)
                        }
                    ]
                }
            })

            let wellDone = {
                title: "Well Done!",
                content: `<p>${t('wellDoneBasic')}</p>`,
                actions: [
                    {
                        label: t('toDashboard'),
                        navigate: "/"
                    },
                ]
            }

            const mike5Map = {
                'big5': {
                    content: `<p>${t('wellDoneBasic')}</p><p>${t('wellDoneAssistanceCore')}</p>`,
                    actions: [
                        {label: "Assistance", navigate: "/workout/mike5/assistance"},
                        {label: "Core", navigate: "/workout/mike5/core"},
                    ],
                    textAction: {label: t('backToDashboard'), navigate: '/'}
                },
                'assistance': {
                    content: `<p>${t('wellDoneBasic')}</p><p>${t('wellDoneCore')}</p>`,
                    actions: [
                        {label: "Dashboard", navigate: "/", secondary: true},
                        {label: "Core", navigate: "/workout/mike5/core"},
                    ],
                },
                'core': {
                    content: `<p>${t('wellDoneBasic')}</p>`,
                    actions: [{label: t('toDashboard'), navigate: "/"}],
                }
            }

            if(activeMike5Workout.workoutType === 'mike5') {
                wellDone = {
                    ...wellDone,
                    ...mike5Map[activeWorkout.mike5Component],
                };
            }

            openPopup(wellDone);
            sfx.playWorkoutComplete();

            return {...prevState, started: false};
        }
    }

    const nextExercise = () => {
        if (!(workoutTimer.currentExercise + 1 < workoutTimer.exercisesCount)) {
            return;
        }

        setWorkoutTimer(prevState => ({
            ...prevState,
            currentExercise: prevState.currentExercise + 1,
            countDown: 10,
            currentPhase: 'Get Ready'
        }));
    };

    const endWorkout = () => {
        addToQueue({
            action: 'completeWorkout',
            data: {
                workoutId: activeWorkout.id,
                mike5Component: activeWorkout.mike5Component ?? '',
                completedDateTime:  dateToISOStringKeepTimezone(),
                intendedCompletionDate: dateToISOStringKeepTimezone(currentDate)
            }
        });

        setUserData( (prevState) => {
            return {
                ...prevState,
                workout_progress: [
                    ...prevState.workout_progress,
                    {
                        workout_id: activeWorkout.id,
                        mike5Component: activeWorkout.mike5Component ?? '',
                        completed_datetime:  dateAsWorkoutFormat(),
                        intended_completion_date: dateAsWorkoutFormat(currentDate)
                    }
                ]
            }
        })

        setWorkoutTimer(prevState => ({
            ...prevState,
            paused: true,
        }));

        sfx.playWorkoutComplete();

        openPopup({
            title: "Well Done!",
            content: `<p>${t('wellDoneBasic')}</p>`,
            actions: [
                {
                    label: t('toDashboard'),
                    navigate: "/"
                },
            ]
        });
    }

    const nextPhase = () => {
        if (!workoutTimer.started || workoutTimer.paused) {
            clearInterval(timerInterval.current);
        } else {
            setWorkoutTimer(prevState => getNextTimerState(prevState)); // changes here
        }
    };

    const startWorkoutTimer = async () => {
        if (isMobileSafari) {
            openPopup({
                title: t('soundProblemsSafariTitle'),
                content: `<p>${t('soundProblemsSafari1')}</p><p>${t('soundProblemsSafari2')}</p><p>${t('soundProblemsSafari3')}</p><p>${t('soundProblemsSafari4')}</p>`,
                actions: [
                    {label: t('close'), onClick: startWorkout},
                ],
            })
        } else {
            startWorkout();
        }


    };

    const startWorkout = async () => {
        await requestWakeLock()
        setWorkoutTimer(prevTimerState => ({ ...prevTimerState, started: true }));
        sfx.playGetReady();
    }

    const pauseWorkoutTimer = async () => {
        await releaseWakeLock();
        setWorkoutTimer(prevTimerState => ({ ...prevTimerState, paused: !prevTimerState.paused }));
    };

    const restartWorkout = () => {
        if (window.confirm(t('restartWorkout'))) {
            setWorkoutTimer(prevTimerState => ({
                ...prevTimerState,
                currentPhase: 'Get Ready',
                started: false,
                paused: false,
                currentSet: 1,
                timeSpent: 0,
                countDown: 10
            }));
        }
    };

    useEffect(() => {
        // Run this block of code anytime the selectedWorkoutType changes
        if (selectedWorkoutType && activeWorkout.interval) {
            const { workTime, restTime, sets, link } = activeWorkout.interval;
            const setsCount = sets.length;

            // Set the workoutTimer state with the interval values from the selected workout type
            setWorkoutTimer(prevState => ({
                ...prevState,
                currentPhase: 'Get Ready',
                started: false,
                paused: false,
                countDown: 20,
                workTime: workTime,
                restTime: restTime,
                sets,
                setsCount,
                currentSet: 1,
                timeLeftInSet: workTime,
                totalTime: (workTime + restTime) * setsCount,
                intervalLink: link,
                exercisesCount: activeWorkout.exercises.filter(exercise => exercise.isSelected !== false).length,
                currentExercise: 0,
                hasContinuosTimer:
                    activeWorkout.mike5Component === 'core' ||
                    sets?.length <= 0 ||
                    activeWorkout.hasContinuosTimer,
                timeSpent: 0,
            }));
        }
    }, [activeWorkout, selectedWorkoutType]);

    useEffect(() => {
        timerInterval.current = setInterval(nextPhase, 1000);
        return () => clearInterval(timerInterval.current);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workoutTimer.started, workoutTimer.paused]);

    return {
        workoutTimer,
        startWorkoutTimer,
        pauseWorkoutTimer,
        restartWorkout,
        nextExercise,
        endWorkout
    };
};

export default useWorkoutTimer;